import imgAdda from "./img/artists/Adda.jpg"
import imgBor from "./img/artists/Bor.jpg"
import imgBarbe from "./img/artists/Barbe.jpg"
import imgGarcia from "./img/artists/Garcia.jpg"
import imgHadjab from "./img/artists/Hadjab.jpg"
import imgHerizi from "./img/artists/Herizi.jpg"
import imgIssiakhem from "./img/artists/Issiakhem.jpg"
import imgLegault from "./img/artists/Legault.jpg"
import imgMolinero from "./img/artists/Molinero.jpg"
import imgMomein from "./img/artists/Momein.jpg"
import imgTomek from "./img/artists/Tomek.jpg"
import imgPaounoff from "./img/artists/Paounoff.jpg"
import imgSortais from "./img/artists/Sortais.jpg"
import imgTersen from "./img/artists/Tersen.jpg"
import imgYarou from "./img/artists/Yarou.jpg"

const Artists = [
    {
      "first": "Kais",
      "last": "Adda",
      "img": imgAdda
    },
    {
      "first": "Priscilla",
      "last": "Benyahia",
      "img": imgBarbe
    },
    {
      "first": "Houari",
      "last": "Bouchenak",
      "img": imgBor
    },
    {
      "first": "Celia",
      "last": "Bougdal",
      "img": imgGarcia
    },
    {
      "first": "Sofiane",
      "last": "Boukhari",
      "img": imgHadjab
    },
    {
      "first": "Naila",
      "last": "Haba",
      "img": imgHerizi
    },
    {
      "first": "Karim",
      "last": "Hadjab",
      "img": imgIssiakhem
    },
    {
      "first": "Helmi",
      "last": "Charni",
      "img": imgLegault
    },
    {
      "first": "Soufiane",
      "last": "Chemcham",
      "img": imgMolinero
    },
    {
      "first": "Youcef",
      "last": "Krache",
      "img": imgMomein
    },
    {
      "first": "Brice",
      "last": "Krummenacker",
      "img": imgPaounoff
    },
    {
      "first": "Jeff",
      "last": "Morsli",
      "img": imgSortais
    },
    {
      "first": "Nazhachi",
      "last": "",
      "img": imgTersen
    },
    {
      "first": "Clea",
      "last": "Rekhou",
      "img": imgTomek
    },
    {
      "first": "Ryadh",
      "last": "Roublev",
      "img": imgYarou
    },
    {
      "first": "Fethi",
      "last": "Sahraoui",
      "img": imgYarou
    },
    {
      "first": "Abdo",
      "last": "Shanan",
      "img": imgYarou
    },
    {
      "first": "Syfax",
      "last": "",
      "img": imgYarou
    },
    {
      "first": "Sofiane",
      "last": "Zouggar",
      "img": imgYarou
    }
]

export default Artists