import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import './Modal.scss'
import artists from './Artists'
import { BrowserView, MobileView } from 'react-device-detect';
import PreloadLCPImage from './PreloadLCPImage'
import logoIcon from './img/logo-aziimut---yellow.svg'
import spinner from './img/spinner-1.svg'

// https://upmostly.com/tutorials/modal-components-react-custom-hooks

// const ImgArtist = () => {

//   const [loading, setLoading] = useState(true);
//   const imgRef = useRef();
  
//   useEffect(() => {
//       if (loading) {
//           setTimeout(() => {
//               if (loading) {
//                   setLoading(false);
//               }
//           }, 100);
//       }
//   }, [loading, setLoading]);

//   useEffect(() => {
//     imgRef.current.src = loading ?
//         "https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" :
//         imgSortais;
//     }, [loading, imgRef]);

//     return (
//     <div className="img-artist">
//         <img ref={imgRef} alt="okay" />
//     </div>
//   );
// }

const imgsPreloadList = [
  "./img/artists/Adda.jpg",
  "./img/artists/Bor.jpg",
  "./img/artists/Garcia.jpg",
  "./img/artists/Hadjab.jpg",
  "./img/artists/Herizi.jpg",
  "./img/artists/Issiakhem.jpg",
  "./img/artists/Legault.jpg",
  "./img/artists/Molinero.jpg",
  "./img/artists/Momein.jpg",
  "./img/artists/Tomek.jpg",
  "./img/artists/Paounoff.jpg",
  "./img/artists/Sortais.jpg",
  "./img/artists/Tersen.jpg",
  "./img/artists/Yarou.jpg",
  "./img/artists/Zohra.jpg"
];

const ArtistsListDesktop = () => {
  return(
    <ul>
      {artists.map(artist => (        
        <>
        <li
          key={`id-${artist.id}`}
        >{artist.first} {artist.last}</li>
        </>
      ))}
    </ul>
  )
}

const ArtistsListMobile = () => {
  return(
    <ul>
      {artists.map(artist => (        
        <ArtistMobile key={artist.last} artist={artist}/>
      ))}
    </ul>
  )
}

const ArtistMobile = (props) => {
  const {first, last} = props.artist;
  console.log(first)
  return(
  <>
    <li
    className={''}
    key={last}
    >{first} {last}</li>
  </>

  )
}

const ModalExhibitions = () => {
  return(
  <>
    <div className="modal-content modal-content--exhibitions">
      <section className="panel-1">
        <a href="https://thesalon.paris/" target="_blank">
          <h3>
            Aziimut + Collectif 220
          </h3>
        </a>
        <h4>
          <span className="expo-name">Mémoires Croisées</span><br />
          Vernissage le <span>08 Nov - 18H</span><br />
          Exposition du 09 au 14 Nov 2024<br />
          <span className='address'>30 bis Rue de Paradis
          75010 Paris France</span>
        </h4>
      </section>
    </div>
    <BrowserView>
      <ArtistsListDesktop />
    </BrowserView>
    <MobileView>
      <ArtistsListMobile />
    </MobileView>
  </>
)}

const ModalAbout = () => (
  <div className="modal-content modal-content--about">
    <div className="modal---valign">
      <div className={"icon"}>
        <img src={logoIcon} alt="Aziimut Logo Icon"/>
      </div>
      <p className={"text-1"}>Aziimut is developing traveling exhibitions <br />based on temporary urbanism.<br /><br />
The artistic collective’s commitment is to offer an alternative to the usual cultural consensus, capturing the essence of the ephemeral in a constantly changing world.<br /><br />
The long-term aim is to create an incubator for talent, supporting the production and presentation of new works by emerging and established local<br />and international artists.
      </p>
      <a href="mailto:contact@aziimut.com" className={"mail"}>contact@aziimut.com</a>
    </div>
  </div>
)

// const ModalContact = () => (
//   <div className="modal--wrapper">
//     <p>contact@aziimut.com</p>
//   </div>
// )

const Modal = ({ isShowing, hide, modalType }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className={`modal modal--${modalType}`}>
        <button onClick={hide} className="logo">AZIIMUT</button>
        <div className="modal-header">
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {(() => {
          switch (modalType) {
            // case 'contact':
            //   return <ModalContact />
            case 'exhibitions':
              return <ModalExhibitions />
            case 'about':
              return <ModalAbout />
            default:
              return null
          }
        })()}
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;